import * as React from "react";
import { HomeLink } from "@elevio/kb-kit/lib/components/meta";
import Page from "../layouts/Page";
import ErrorPage from "../components/ErrorPage";

export default function() {
	return (
		<Page title="Error - WildApricot Help">
			<ErrorPage title="Sorry, something went wrong">
				<p>Please visit to the <HomeLink>home page</HomeLink> and try again</p>
				<p>If you would like to report this problem, please <a href="mailto:wa.support@personifycorp.com">send us an email</a>,<br />letting us know the page you were trying to reach and<br />any other relevant information you'd like to provide.</p>
				<p>Thank you for your help!</p>
			</ErrorPage>
		</Page>
	);
}
