import React from "react";
import SearchInput from '../components/SearchInput';

type ErrorPageProps = {
	children: React.ReactNode;
	title: string;
	showSearch?: boolean;
};
export default function ErrorPage({ children, title, showSearch }: ErrorPageProps) {
	return (
		<div className="messageBox">
			<div className="messageBoxTitle">{title}</div>
			<div className="messageBoxContent">
				{children}
			</div>
			{showSearch && 
				<div className="sf_cols">
					<div className="sf_colsIn greyBox">
						<SearchInput size="small" />
					</div>
				</div>
			}
		</div>
	);
}
