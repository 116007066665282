import { useNotifications } from "@elevio/kb-kit/lib/hooks";
import cx from "classnames";

export default function Alert() {
	const { notification, clearNotification } = useNotifications();
	if (!notification) return <></>;
	clearNotification();
	return (
		<div className="alert">
			<div className={cx("statusBox", `statusBox${notification.type === 'error' ? 'Error' : 'Success'}`, "statusMessage", "showIcon")}>
				<em className="fas fa-check"></em>
				<em className="fas fa-times"></em>
				<span className="text">{notification.message}</span>
			</div>
		</div>
	);
}
