import React from "react";

type SidebarProps = {
	children?: React.ReactNode;
};
export default function Sidebar({children}: SidebarProps) {
	if (!children) return null;

	return (
		<div className="sidebar">
			<div className="inner">
				<div className="scrollbar">
					{children}
				</div>
			</div>
		</div>
	);
}
