import React from "react";

export default function Header() {
	return (
		<div className="waHeader">
		<div className="fixed">
			<div className="inner fixedWidth">
				<div className="logo">
					<a href="https://www.wildapricot.com/">
						<img alt="Membership Software - WildApricot" title="Membership Software - WildApricot" src="https://s.wildapricot.net/StaticImages/v7/Logo/WildApricot.png" />
					</a>
				</div>
				<div className="container1 grow">
					<div className="container2 grow">
					</div>
					<div className="container3">
						<span className="button-holder button-holder-center button-holder-support desktop"><a title="WildApricot Support" className="button button-small button-solid button-support" href="https://support.wildapricot.com/">WildApricot Support</a></span>
						<div className="question">Not a WildApricot client?</div>
						<div>
							<span className="button-holder button-holder-center button-holder-trial"><a title="Try it Free for 30 Days" className="button button-small button-solid button-trial" rel="nofollow" href="https://register.wildapricot.com/">Try Now</a></span>
						</div>
					</div>
				</div>
				<div className="hamburger">
					<span className="button-holder button-holder-center button-holder-support mobile"><a title="WildApricot Support" className="button button-small button-solid button-support" href="https://support.wildapricot.com/">WildApricot Support</a></span>
					<a className="button-menu"><i className="fas fa-bars"></i></a>
				</div>
			</div>
		</div>
	</div>
	);
}
