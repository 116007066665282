import * as React from 'react';
import Page from '../layouts/Page';
import ErrorPage from '../components/ErrorPage';

export default function() {
	return (
		<Page title="Page not available - WildApricot Help">
			<ErrorPage title="Page not available">
				<p>The article you have requested is not publicly available.</p>
			</ErrorPage>
		</Page>
	);
}
