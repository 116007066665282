import * as React from 'react';
import * as Categories from '@elevio/kb-kit/lib/components/categories';
import Page from '../layouts/Page';
import CategoryCard from '../components/CategoryCard';

export default function() {
	return (
		<Page title="WildApricot Help - What can we help you with?">
			<div className="categories">
				<Categories.Loop>
					<CategoryCard limit={5} />
				</Categories.Loop>
			</div>
		</Page>
	);
}
