import React from 'react';
import { useCategory } from '@elevio/kb-kit/lib/hooks';
import * as Category from '@elevio/kb-kit/lib/components/category';
import * as Categories from '@elevio/kb-kit/lib/components/categories';
import * as Article from '@elevio/kb-kit/lib/components/article';
import * as Articles from '@elevio/kb-kit/lib/components/articles';
import * as RelatedArticles from "@elevio/kb-kit/lib/components/relatedArticles";
import Link from './Link';

type CategoryCardProps = {
	categoryId?: string;
	limit?: number;
	homeLink?: boolean;
	suggestedArticles?: boolean;
	expand?: boolean;
};
export default function CategoryCard({ categoryId, limit, homeLink, suggestedArticles, expand }: CategoryCardProps) {
	const category = useCategory({ categoryId });	
	if (!category) return null;

	return (
		<div className="category">
			{homeLink && <HomeLink />}

			{suggestedArticles && <>
				<h4 className="category-title">Suggested articles</h4>
				<RelatedArticleList />
			</>}

			{!limit ? <CategoryTitle /> : <Category.Link className="category-title">{category.title}</Category.Link>}
			
			{!expand && category.subCategories.length > 0 && 
				<ul className="subcategories">
					<Categories.Loop>
						<SubCategory />
					</Categories.Loop>
				</ul>
			}

			{category.articles.length > 0 && 
				<ArticleList categoryId={categoryId} limit={limit} />
			}

			{expand && category.subCategories.length > 0 && 
				<Categories.Loop>
					<>
						<CategoryTitle />
						<ArticleList categoryId={categoryId} />
					</>
				</Categories.Loop>
			}

			{limit && category.articles.length > limit &&
				<Category.Link className="more">{category.articles.length - limit} more</Category.Link>
			}
		</div>
	);
}

function HomeLink(){
	return (
		<Link href="/" className="homeLink">
			<i className="fas fa-home"></i>
		</Link>
	);
}

function CategoryTitle(){
	return (
		<h4 className="category-title"><Category.Title /></h4>
	)	
}

type ArticlesProps = {
	categoryId?: string;
	limit?: number;
};
function ArticleList({categoryId, limit}: ArticlesProps){
	return (
		<ul className="articles">
			<Articles.Loop categoryId={categoryId} limit={limit}>
				<li>
					<Article.Link>
						<Article.Title />
					</Article.Link>
				</li>
			</Articles.Loop>
		</ul>
	);
};

function RelatedArticleList(){
	return (
		<ul className="articles">
			<RelatedArticles.Loop>
					<li>
						<Article.Link className="link-color">
							<Article.Title />
						</Article.Link>
					</li>
				</RelatedArticles.Loop>
		</ul>
	);
}

function SubCategory({ categoryId }: CategoryCardProps){
	const category = useCategory({ categoryId });
	if (!category) return null;

	return (
		<li>
			<i className="fas fa-plus"></i>
			<Category.Link className="subcategory-title">{category.title}</Category.Link>
		</li>
	);
}
