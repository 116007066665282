import React from "react";
import InnerHtml from "@elevio/kb-kit/lib/components/InnerHtml";

type CustomHTMLProps = {
	filename: string;
};
export default function CustomHTML({filename}: CustomHTMLProps) {
	return (
		<InnerHtml html={require(`./customHTML/${filename}.html`).default} />
	);
}
