import * as React from 'react';
import Page from '../layouts/Page';
import ErrorPage from '../components/ErrorPage';

export default function() {
	return (
		<Page title="Page not found - WildApricot Help">
			<ErrorPage title="Page not found" showSearch={true}>
				<p>Unfortunately, the page you've requested no longer exists.<br />Please use the search bar below to find the information you’re looking for.</p>
			</ErrorPage>
		</Page>
	);
}
