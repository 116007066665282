import React from "react";
import { useArticleFeedback, IFeedbackForm } from "@elevio/kb-kit/lib/hooks";

export default function ArticleFeedback() {
	const {
		stage,
		isFeedbackEnabled,
		values,
		isFeedbackFormSubmitting,
		emailFieldIsHidden,
		showAnonymousWarning,
		messageError,
		emailError,
		onSubmit,
		onNegativeReaction,
		onPositiveReaction,
		onFeedbackSkipped,
		handleMessageChange,
		handleEmailChange,
	} = useArticleFeedback();

	if (!isFeedbackEnabled) return null;

	switch(stage) {
		case "positive":
		case "submitted":
			return <FeedbackSuccess />;

		case "negative":
			return (
				<FeedbackForm
					onFeedbackSkipped={onFeedbackSkipped}
					onSubmit={onSubmit}
					isSubmitting={isFeedbackFormSubmitting}
					isEmailHidden={emailFieldIsHidden}
					handleMessageChange={handleMessageChange}
					handleEmailChange={handleEmailChange}
					showAnonymousWarning={showAnonymousWarning}
					values={values}
					messageError={messageError}
					emailError={emailError}
				/>
			);

		case "error":
			return <FeedbackError />;

		default:
			return (
				<FeedbackButtons
					onNegativeReaction={onNegativeReaction}
					onPositiveReaction={onPositiveReaction}
				/>
			);
	}
}

type ContainerProps = {
	children?: React.ReactNode;
	title: string;
};
function Container({ children, title }: ContainerProps){
	return (
		<div className="article-feedback">
			<p>{title}</p>
			{ children }
		</div>
	)
}

type FeedbackButtonsProps = {
	onNegativeReaction: () => void;
	onPositiveReaction: () => void;
};
export function FeedbackButtons({
	onNegativeReaction,
	onPositiveReaction,
}: FeedbackButtonsProps) {
	return (
		<Container title="Was this helpful?">
			<div className="options">
				<a className="option down" type="button" onClick={onNegativeReaction}>
					<i className="fas fa-thumbs-down"></i>
				</a>
				<a className="option up" type="button" onClick={onPositiveReaction}>
					<i className="fas fa-thumbs-up"></i>
				</a>
			</div>
		</Container>
	);
}

const FeedbackError = () => {
	return (
		<Container title="Sorry, something went wrong" />
	);
};

function FeedbackSuccess() {
	return (
		<Container title="Thank you" />
	);
};

type ErrorMessageProps = {
	error?: string;
};
function ErrorMessage({ error }: ErrorMessageProps) {
	return (error ? <p className="validator invalid">* {error}</p> : null);
}

const EmailBox = ({ error, ...props }) => {
	return (
		<>
			<input
				className={error ? "invalid" : ""}
				type="email"
				name="email"
				placeholder="Email (optional)"
				{...props}
			/>
			<ErrorMessage error={error} />
		</>
	);
};

const MessageBox = ({ error, ...props }) => {
	return (
		<>
			<textarea
				name="feedback"
				rows={8}
				placeholder="Type your feedback here"
				{...props}
			/>
			<ErrorMessage error={error} />
		</>
	);
};

type SubmitButtonProps = {
	disabled: boolean;
};
function SubmitButton({ disabled }: SubmitButtonProps) {
	return (
		<button className="button" type="submit" disabled={disabled}>Submit</button>
	);
}

type SkipButtonProps = {
	onClick: () => void;
	disabled: boolean;
};
function SkipButton({onClick, disabled}: SkipButtonProps) {
	return (
		<button
			className="button button-light"
			onClick={onClick}
			disabled={disabled}
			data-testid="article-feedback-skip-button"
			type="button"
		>Skip</button>
	);
}

type TextInputElement = HTMLInputElement | HTMLTextAreaElement | HTMLFormElement;

type FeedbackFormProps = {
	onFeedbackSkipped: () => void;
	onSubmit: (event?: React.FormEvent<TextInputElement> | undefined) => void;
	isSubmitting: boolean;
	isEmailHidden: boolean;
	handleMessageChange: (event: string | React.ChangeEvent<TextInputElement>) => void;
	handleEmailChange: (event: string | React.ChangeEvent<TextInputElement>) => void;
	showAnonymousWarning: boolean;
	values: IFeedbackForm;
	emailError: string | null;
	messageError: string | null;
};
export function FeedbackForm({
	onFeedbackSkipped,
	onSubmit,
	isSubmitting,
	isEmailHidden,
	handleMessageChange,
	handleEmailChange,
	showAnonymousWarning,
	values,
	messageError,
	emailError,
}: FeedbackFormProps) {

	return (
		<Container title="How can it be improved?">
			<form className="form form-small" onSubmit={onSubmit} noValidate>
				<MessageBox
					disabled={isSubmitting}
					error={messageError}
					value={values.feedback}
					onChange={handleMessageChange}
				/>

				{!isEmailHidden && (
					<EmailBox
						disabled={isSubmitting}
						value={values.email}
						onChange={handleEmailChange}
						error={emailError}
					/>
				)}

				{showAnonymousWarning && !values.email && (
					<p>Are you sure you want to send this anonymously?</p>
				)}

				<div className="buttons">
					<SubmitButton disabled={isSubmitting || !!emailError || !!messageError} />

					{!showAnonymousWarning && <SkipButton disabled={isSubmitting} onClick={onFeedbackSkipped} />}
				</div>
			</form>
		</Container>
	);
}
