import React from "react";
import SearchInput from './SearchInput';

export default function TopBackground() {
	return (
		<div className="sf_cols topBackground">
			<div className="sf_colsOut bg" data-placeholder-label="Background image"></div>
			<div className="sf_colsOut text" data-placeholder-label="Text">
				<div className="sf_colsIn fixedWidth">
					<div className="sfContentBlock">
						<h1>WildApricot Help</h1>
						<h2>What can we help you with?</h2>
						<SearchInput size="normal" />
					</div>
				</div>
			</div>
		</div>
	);
}
