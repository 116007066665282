import React from "react";

export default function Loader() {
	return (
		<div className="page-loader" style={{width: '100%', height: '100vh'}}>
			<div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				<img src="https://s.wildapricot.net/StaticImages/v2/Preloaders/teal.gif" />
			</div>
		</div>
	);
}
