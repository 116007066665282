import React from "react";
import Head from '../components/Head';
import Loader from "../components/Loader";
import Header from "../components/Header";
import TopBackground from "../components/TopBackground";
import CustomHTML from "../components/CustomHTML";
import Alert from "../components/Alert";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";

type PageProps = {
	title: string;
	children: React.ReactNode;
	sidebar?: React.ReactNode;
};
export default function Page({ title, children, sidebar }: PageProps) {
	return (
		<>
			<Head title={title} cssVersion={134.11} />
			<Loader />

			<main style={{display: "none"}}>
				<Header />
				<TopBackground />
				<Alert />
				<CustomHTML filename="Hotjar" />

				<div className="fixedWidth" data-sidebar={!!sidebar}>
					<Sidebar>{sidebar}</Sidebar>
					<div className="content">{children}</div>
				</div>

				<Footer />
			</main>
			
			<CustomHTML filename="InternalScripts" />
			<CustomHTML filename="CustomJS" />
			<CustomHTML filename="ExternalScripts" />
		</>
	);
}
