import * as React from 'react';
import { useArticleFragment } from "@elevio/kb-kit/lib/hooks";
import * as Article from '@elevio/kb-kit/lib/components/article';
import Page from '../layouts/Page';
import CategoryCard from '../components/CategoryCard';
import ArticleFeedback from '../components/ArticleFeedback';

export default function() {
	const article = useArticleFragment();
	if (!article) return null;

	return (
		<Page title={article.title + ' - WildApricot Help'} sidebar={<CategoryCard homeLink={true} expand={true} suggestedArticles={true} />}>
			<h1><Article.Title /></h1>
			<Article.Body className="articleBody" />
			<ArticleFeedback />
		</Page>
	);
}
