import * as React from 'react';
import * as Search from '@elevio/kb-kit/lib/components/search';
import * as SearchResults from '@elevio/kb-kit/lib/components/searchResults';
import cx from "classnames";
import { useSearch } from '@elevio/kb-kit/lib/hooks/useSearch';
import { useSearchResults } from '@elevio/kb-kit/lib/hooks/useSearchResults';

type SearchInputProps = {
	size: 'normal' | 'big' | 'small';
};
export default function SearchInput({size}: SearchInputProps) {
	const { isOpen } = useSearch();

	return (
		<div className="search-container">
			<div className={cx("form", `form-${size}`)}>
				<Search.Input type="text" placeholder="Search for help" />
			</div>
			<span className="fas fa-search placeholder"> </span>
			{isOpen && <SearchDropdown />}
		</div>
	);
}

function SearchDropdown() {
	const { value } = useSearch();
	const { isFetching, searchTerm } = useSearchResults();

	if (value.length === 0) return null;

	if (isFetching || value !== searchTerm) {
		return (
			<Dropdown>
				<Preloader />
			</Dropdown>
		);
	}

	return (
		<Dropdown>
			<SearchResults.LoopResults>
				<ArticleTitle />
			</SearchResults.LoopResults>
			<SearchResults.IsNotEmpty>
				<SearchMore />
			</SearchResults.IsNotEmpty>
			<SearchResults.IsEmpty>
				<NoResults />
			</SearchResults.IsEmpty>
		</Dropdown>
	);
}

function Dropdown({ children }) {
	return (
		<div className="dropdown">
			{children}
		</div>
	);
}

function Preloader(){
	return (
		<div className="preloader-horizontal" />
	);
}

function NoResults(){
	return (
		<div className="noResults">No results</div>
	);
}

function ArticleTitle() {
	return (
		<SearchResults.ArticleLink
			className="articleTitleRoot"
			data-testid="article-link"
		>
			<span className="title">
				<div>
					<SearchResults.ArticleTitle />
				</div>
			</span>
		</SearchResults.ArticleLink>
	);
}

function SearchMore() {
	const { isFetching, loadMore, hasMore } = useSearchResults();
	if (!hasMore) return null;

	return (
		<button className="button button-small"
			disabled={isFetching || !hasMore}
			onClick={loadMore}
		>Load more</button>
	);
}


