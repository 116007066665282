import React from 'react';
import RouterLink from '@elevio/kb-kit/lib/componentsInternal/RouterLink';
import { getExtraPagePath } from '@elevio/kb-kit/lib/utils/routes';

type LinkProps = {
	href: string;
	isActive?: boolean;
	className?: string;
	children?: React.ReactNode;
};
export default function Link({className, isActive, children, href}: LinkProps) {
	const to = getExtraPagePath({ path: href.replace(/^\//, '')});
	return React.createElement(RouterLink, { to, className, isActive: () => isActive || false}, children);
}
