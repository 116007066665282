import * as React from 'react';
import { useCategory } from '@elevio/kb-kit/lib/hooks';
import Page from '../layouts/Page';
import CategoryCard from '../components/CategoryCard';

export default function() {
	const category = useCategory();
	if (!category) return null;

	return (
		<Page title={category.title + ' - WildApricot Help'}>
			<CategoryCard homeLink={true} expand={true} />
		</Page>
	);
}
