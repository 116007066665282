import React from "react";
import { Helmet } from "@elevio/kb-kit/lib/components/meta";

type HeadProps = {
	title: string;
	cssVersion: number;
}
export default function Head({title, cssVersion}: HeadProps) {
	return (
		<>
			<Helmet>
				<title>{title}</title>

				<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
				<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />

				<link rel="apple-touch-icon" sizes="180x180" href="https://s.wildapricot.net/StaticImages/v3/Favicon/gethelp/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="https://s.wildapricot.net/StaticImages/v3/Favicon/gethelp/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="https://s.wildapricot.net/StaticImages/v3/Favicon/gethelp/favicon-16x16.png" />
				<link rel="manifest" href="https://s.wildapricot.net/StaticImages/v3/Favicon/gethelp/manifest.json" />
				<link rel="mask-icon" href="https://s.wildapricot.net/StaticImages/v3/Favicon/gethelp/safari-pinned-tab.svg" color="#ff8703" />
				<link rel="shortcut icon" href="https://s.wildapricot.net/StaticImages/v3/Favicon/gethelp/favicon.ico" />
				<meta name="apple-mobile-web-app-title" content="WildApricot" />
				<meta name="application-name" content="WildApricot" />
				<meta name="msapplication-TileColor" content="#603cba" />
				<meta name="msapplication-config" content="https://s.wildapricot.net/StaticImages/v3/Favicon/gethelp/browserconfig.xml" />
				<meta name="theme-color" content="#ffffff" />

				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="monitoring" content="monitoring_wac" />

				<meta property="og:site_name" content="WildApricot Help" />
				<meta property="og:type" content="article" />

				<link rel="stylesheet" type="text/css" href="https://use.typekit.net/xtj1nmg.css" />
				<link rel="stylesheet" type="text/css" href="https://kit-pro.fontawesome.com/releases/latest/css/pro.min.css" />
				<link rel="stylesheet" type="text/css" href={`https://s.wildapricot.net/Styles/v${cssVersion}/Elevio.css`} />
			</Helmet>
			<link rel="stylesheet" type="text/css" href={`https://s.wildapricot.net/Styles/v${cssVersion}/Elevio.css`} />
		</>
	);
}
